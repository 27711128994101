@import url("https://fonts.googleapis.com/css2?family=Corinthia:wght@700&family=Dongle:wght@300&family=Fredoka:wght@600&family=M+PLUS+1:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,600&family=Rubik:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "M PLUS 1", sans-serif;
}

body{
  background: rgba(217, 240, 209, 0.5);
}
